/* greater than 600 px */
/*Navbar CSS Below... */
.navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0px;
  }
  .navbar h1 {
    color: #5a1db5;
    font-size: 32px;
  }
  .navbar .links {
    margin-left: auto;
    font-size: 20px;
  }
  .navbar a {
    margin-left: 10px;
    text-decoration: none;
    padding: 6px;
  }
  .navbar a:hover {
    color: #5a1db5;
  }
  
  