.homepage {
    max-width: 1200px;
    border-radius: 0;
    margin: 0 auto;
    height: 900px;
}
.log-in-tile {
    max-width: 600px;
    margin: 30px auto;
    padding: 5px;
    border: solid 3px #5a1db5;
    background: #eee;
}
.log-in-tile * {
    padding: 10px;
    margin: 5px auto;
    background: none;
}
.log-in-tile a {
    text-decoration: none;
    padding: none;
    margin: none;
}
.log-in-tile p {
    font-size: 20px;
}
.log-in-button {
    background: #5a1db5;
    color: #fafafa;
    border: none;
    font-size: 18px;
}
.log-in-button:hover {
    cursor: pointer;
}
@media (max-height: 900px) {
    .log-in-tile {
        max-width: 350px;
        border-width: 2px;
    }
}