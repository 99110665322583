.about_page {
    width: 100%;
}
.about_page h1 {
    color: #5a1db5;;
}
.about_body {
    padding: 5px;
    text-align: justify;
    max-width: 1000px;
    margin: auto;
}
