@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


* {
  margin: 0 5px;
  font-family: "Quicksand";
  color: #333;
  background-color: #fafafa;
  border-radius: 6px;
  text-align: center;
}
